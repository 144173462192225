function init_greet() {
    $greet_target = $('.js-greet');

    if($greet_target.length < 1) {
        return;
    }

    var d = new Date();
    var hour = d.getHours();
    var greet = null;

    if ( hour >= 5 && hour <= 11 ) {
        greet = 'Goede&shy;morgen,';
    } else if ( hour >= 12 && hour <= 17 ) {
        greet = 'Goede&shy;middag,';
    } else if ( hour >= 18 || hour <= 4 ) {
        greet = 'Goeden&shy;avond,';
    }

    $greet_target.html(greet);
}