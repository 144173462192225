function toggleCart() {
    $body = $('body');
    $body.removeClass('search-bar-open');
    $body.removeClass('m-open');
    $body.toggleClass('cart-open');
    if($body.hasClass('cart-open')) {
      $('#navbarCollapse').collapse('hide');
    }
}

function openCart() {
  $body = $('body');
  event.stopPropagation();
  $body.removeClass('search-bar-open');
  $body.removeClass('m-open');
  $('body').addClass('cart-open');
  $('#navbarCollapse').collapse('hide');
}

function init_cart(firstrun) {
    $body = $('body');
    check_cart_empty();

    // Check if staffel is showing
    if($('.container .product .js-product_has_prijsafspraak').length > 0) {
      $('table.staffelkorting').hide();
    }

    $('.container .product .summary ins .woocommerce-Price-amount').after('<small class="ex-btw-notice">(incl. btw)</small>');
    $('.container .product .summary .price > .woocommerce-Price-amount:only-child').after('<small class="ex-btw-notice">(incl. btw)</small>');

    if(firstrun) {
      $("main, footer, .close-shopping-cart").click(function() {
        $body.removeClass('cart-open');
      });

      $(".cart-menu, .open-cart").click(function(event) {
          event.stopPropagation();
      });
    }

    $('.js-open-cart').on('click', function() {
      openCart();
    });

    $('.woocommerce-notices-wrapper').on('click', '.js-notice-open-cart', function() {
      openCart();
    });

    // DOWN
    if(!firstrun) {
      $("header .shopping-cart .quantity_down").off();
    }
    $("header .shopping-cart .quantity_down").click(function() {
      event.preventDefault();
      $body.addClass('cart-edited');
      var $input = $(this).next('.quantity').find('.qty');
      var current_value = $input.val();
      var new_value = 0;

      if(current_value > 0) {
        new_value = current_value - 1;
      }

      var $this_row = $(this).closest('.shopping-row');
      $this_row.attr('data-cart-quantity', new_value);
      $input.val(new_value);
      update_cart_counter();
    });

    // UP
    if(!firstrun) {
      $("header .shopping-cart .quantity_up").off();
    }
    $("header .shopping-cart .quantity_up").click(function() {
      event.preventDefault();
      $body.addClass('cart-edited');
      var $input = $(this).prev('.quantity').find('.qty');
      var current_value = parseInt($input.val());
      var new_value = 0;
      var max_quantity = parseInt($input.attr('max'));

      var next_value = current_value + 1;
      if(next_value > max_quantity) {
        new_value = current_value;
      }
      else {
        new_value = next_value;
      }

      var $this_row = $(this).closest('.shopping-row');
      $this_row.attr('data-cart-quantity', new_value);
      $input.val(new_value);
      update_cart_counter();
    });

    // USER TYPING
    if(!firstrun) {
      $("header .shopping-cart input[type='number']").off('change');
    }
    $("header .shopping-cart input[type='number']").change(function() {
      $body.addClass('cart-edited');
      var $this_row = $(this).closest('.shopping-row');
      var new_value = parseInt($(this).val());
      $this_row.data('cart-quantity', new_value);
      update_cart_counter();
    });

    // REMOVE ITEM
    if(!firstrun) {
      $('.js-delete-cart-item').off('click');
    }
    $('.js-delete-cart-item').on('click', function() {
      event.preventDefault();
      $body.addClass('cart-updating');

      var $to_be_removed_row = $(this).parent().parent();
      var to_be_removed_key = $to_be_removed_row.attr('data-cart-key');

      if(to_be_removed_key.length > 0) {
        jQuery.ajax({
          url: php_vars.ajax_url,
          type: 'POST',
          dataType: 'json',
          data: {
              action: 'remove_cart_item', // Use the key used in functions.php
              removed_item_key: to_be_removed_key
          }
        })
        .done(function(data_remove) {
          if(data_remove.success === true) {
            $to_be_removed_row.slideUp('normal', function() {$to_be_removed_row.remove();});

            $('header .js-shipping-total').html(data_remove.new_shipping);
            $('header .js-subtotal').html(data_remove.new_subtotal);
            $('header .js-cart-total').html(data_remove.new_price)
            $('header .shopping-cart').attr('data-cart-total-qty', data_remove.amount_items);;

                // Is empty?
                var $counter = $('.cart-menu .count');

                if(parseInt(data_remove.amount_items) == 0) {
                  $counter.fadeOut(100, function() {
                    $counter.text('');
                    $body.addClass('cart-empty');
                  });
                }
                else {
                  $counter.fadeOut(100, function() {
                    $counter.text(data_remove.amount_items).fadeIn(200);
                  });
                  $body.removeClass('cart-empty');
                }
          }

          $body.removeClass('cart-updating');
        });
      }
    });

    // INIT UPDATE
    if(!firstrun) {
      $('.update_cart').off('click');
    }
    $(".update_cart").click(function() {
      event.preventDefault();
      $body.addClass('cart-updating');
      var changed_cart_items = [];

      $("header .shopping-cart .shopping-row").each(function() {
        var $row = $(this);
        var new_qty = parseInt($row.find('.quantity').find('.qty').val());
        changed_cart_items.push({
          'cart_item_key': $row.data('cart-key'),
          'quantity': new_qty
        });

        if(new_qty === 0) {
            $row.slideUp('normal', function() {$(this).remove();});
        }
      });

      jQuery.ajax({
          url: php_vars.ajax_url,
          type: 'POST',
          dataType: 'json',
          data: {
              action: 'updatecart', // Use the key used in functions.php
              changed_cart_items: changed_cart_items
          }
      } )
      .done(function(data) {
          if(data === true) {
            // GET NEW CART
            jQuery.ajax({
                url: php_vars.ajax_url,
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'getUpdatedCart' // Use the key used in functions.php
                }
            })
            .done(function(data2) {
                if(data2.new_cart_items.length > 0) {
                    for(i = 0; i < data2.new_cart_items.length; i++) {
                        $matching_row = $("header .shopping-cart .shopping-row[data-cart-key='" + data2.new_cart_items[i].key + "']");

                        if($matching_row.length > 0) {
                            $matching_row.find('.shopping-row__price').html(data2.new_cart_items[i].price_html);
                        }
                    }
                  }

                $('header .js-shipping-total').html(data2.new_shipping);
                $('header .js-cart-total').html(data2.new_price);
                $('header .js-subtotal').html(data2.new_subtotal);
                $('header .shopping-cart').attr('data-cart-total-qty', data2.amount_items);

                // Is empty?
                if(parseInt(data2.amount_items) == 0) {
                  $body.addClass('cart-empty');
                }
                else {
                  $body.removeClass('cart-empty');
                }

                // FINISHED
                $body.removeClass('cart-edited');
                $body.removeClass('cart-updating');
            });

          }
      } )
      .fail( function( jqXHR, textStatus, errorThrown ) { // HTTP Error
          console.error( errorThrown );
      } );
    });





    // AJAX CART
    jQuery(function($) {
      $(".single_add_to_cart_button").addClass("ajax_add_to_cart");

      $(".post-type-archive-product").on("click", ".quantity input", function() {
          return false;
      });

      if(!firstrun) {
        $(".archive").off('change input');
      }
      $(".archive").on("change input", ".quantity .qty", function() {
          var add_to_cart_button = $(this).parents(".product").find(".add_to_cart_button");
          // For AJAX add-to-cart actions
          add_to_cart_button.data("quantity", $(this).val());
          // For non-AJAX add-to-cart actions
          add_to_cart_button.attr("href", "?add-to-cart=" + add_to_cart_button.attr("data-product_id") + "&quantity=" + $(this).val());
      });

      if(!firstrun) {
        $(".input-text.qty.text").off('keyup mouseup');
      }
      $(".input-text.qty.text").bind('keyup mouseup', function() {
          var value = $(this).val();
          $(".product_quantity").val(value)
      });

      if (typeof wc_add_to_cart_params === 'undefined')
          return false;

      if(!firstrun) {
        $(document).off('click', '.ajax_add_to_cart');
      }
      $(document).on('click', '.ajax_add_to_cart', function(e) {

        // Return to default method if addons present
          if($('.wc-pao-addon-container').length) {
            return true;
          }

          e.preventDefault();
          var $thisbutton = $(this);
          var $variation_form = $(this).closest('.variations_form');
          var var_id = $variation_form.find('input[name=variation_id]').val();
          $('.ajaxerrors').remove();
          var item = {},
              check = true;
          variations = $variation_form.find('select[name^=attribute]');
          if (!variations.length) {
              variations = $variation_form.find('[name^=attribute]:checked');
          }
          if (!variations.length) {
              variations = $variation_form.find('input[name^=attribute]');
          }
          variations.each(function() {
              var $this = $(this),
                  attributeName = $this.attr('name'),
                  attributevalue = $this.val(),
                  index,
                  attributeTaxName;
              $this.removeClass('error');
              if (attributevalue.length === 0) {
                  index = attributeName.lastIndexOf('_');
                  attributeTaxName = attributeName.substring(index + 1);
                  $this
                      .addClass('required error')
                      .before('<div class="ajaxerrors"><p>Please select ' + attributeTaxName + '</p></div>')
                  check = false;
              } else {
                  item[attributeName] = attributevalue;
              }
          });
          if (!check) {
              return false;
          }

          if ($thisbutton.is('.ajax_add_to_cart')) {
              $thisbutton.removeClass('added');
              $thisbutton.addClass('loading');
              $thisbutton.block({
                message: null,
                overlayCSS: {
                    background: 'white',
                    opacity: 0.4
                }
            });

              // VARIATIONS
              if ($(this).parents(".variations_form")[0]) {
                  var product_id = $variation_form.find('input[name=product_id]').val();
                  var quantity = $variation_form.find('input[name=quantity]').val();
                  var data = {
                      action: 'bodycommerce_ajax_add_to_cart_woo',
                      product_id: product_id,
                      quantity: quantity,
                      variation_id: var_id,
                      variation: item
                  };
              }

              // SIMPLE
              else {
                  // var product_id = $(this).parent().find(".product_id").val();
                  var product_id = $(this).closest('.product[data-product-id]').attr('data-product-id');
                  var quantity = $(this).parent().find(".qty").val();
                  var data = {
                      action: 'bodycommerce_ajax_add_to_cart_woo_single',
                      product_id: product_id,
                      quantity: quantity
                  };
              }

              $('body').trigger('adding_to_cart', [$thisbutton, data]);

// START FLYTO
var eltoDrag = $('.product[data-product-id]').find('.woocommerce-product-gallery__image img').eq(0);
var target = $('.cart-menu > img:visible');
var imgclone = eltoDrag.clone()
    .offset({
    top: eltoDrag.offset().top + (eltoDrag.height() / 2),
    left: eltoDrag.offset().left + (eltoDrag.width() / 2)
})
    .css({
        'opacity': '0.5',
        'position': 'absolute',
        'height': '120px',
        'width': '120px',
        'object-fit' : 'contain',
        'z-index': '99999',
        'border-radius': '1000px',
        'background-color': 'white'
})
    .appendTo($('body'))
    .animate({
        'top': target.offset().top + 10,
        'left': target.offset().left + 15,
        'height': '150px',
        'width': '150px'
}, 700, 'swing');

setTimeout(function() {
  target.addClass('pulse-animation');
}, 800);

imgclone.animate({
    'width': 0,
    'height': 0
}, function () {
    $(this).detach()
});

setTimeout(function() {
  target.removeClass('pulse-animation');
}, 2500);
// END FLYTO


              $.post(wc_add_to_cart_params.ajax_url, data, function(response) {

                // Remove existing notices
                $( '.woocommerce-error, .woocommerce-message, .woocommerce-info' ).remove();

                // Add new notices
                $('.woocommerce-notices-wrapper').html(response.fragments.notices_html);

                  if (!response)
                      return;
                  var this_page = window.location.toString();
                  this_page = this_page.replace('add-to-cart', 'added-to-cart');
                  if (response.error && response.product_url) {
                      window.location = response.product_url;
                      return;
                  }
                  if (wc_add_to_cart_params.cart_redirect_after_add === 'yes') {
                      window.location = wc_add_to_cart_params.cart_url;
                      return;
                  } else {

                    // ITEM SUCCESFULLY ADDED
                    // Start getting new cart
                      // GET NEW CART
                    jQuery.ajax({
                        url: php_vars.ajax_url,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            action: 'getnewcartitems' // Use the key used in functions.php
                        }
                    })
                    .done(function(data2) {
                        if(data2.new_cart_items.length > 0) {

                            // Remove div exists
                            if($('header .shopping-row').length > 0) {
                              $('header .shopping-row').remove();
                            }

                            $('.shopping-cart__title').after(data2.new_cart_items);
                            $body.removeClass('cart-empty');
                        }

                        $('header .js-shipping-total').html(data2.new_shipping);
                        $('header .js-subtotal').html(data2.new_subtotal);
                        $('header .js-cart-total').html(data2.new_price);
                        $('header .shopping-cart').attr('data-cart-total-qty', data2.amount_items);

                        // Is empty?
                        var $counter = $('.cart-menu .count');

                        if(parseInt(data2.amount_items) == 0) {
                          $counter.fadeOut(100, function() {
                            $counter.text('');
                            $body.addClass('cart-empty');
                          });
                        }
                        else {
                          $counter.fadeOut(100, function() {
                            $counter.text(data2.amount_items).fadeIn(200);
                          });
                          $body.removeClass('cart-empty');
                        }

                        // FINISHED
                        init_cart(false);

                        $body.removeClass('cart-edited');
                        $body.removeClass('cart-updating');
                    });
                    // End getting new cart

                      $thisbutton.removeClass('loading');
                      $thisbutton.unblock();
                      // var fragments = response.fragments;
                      // var cart_hash = response.cart_hash;
                  }
              });
              return false;
          } else {
              return true;
          }
      });
  });
    // END AJAX CART


    // Setup fix for iphone view height in cart
    window.onresize = function() {
        var $shopping_cart = $('.shopping-cart');

        console.log($shopping_cart);
        // If desktop
        if(window.innerWidth >= 768) {
          console.log('desktop');
          $shopping_cart.css('max-height', window.innerHeight - 80 + 'px');
        }
        // If mobile
        else {
          console.log('mobile');
          $shopping_cart.css('max-height', window.innerHeight - 57 + 'px');
        }
    };
    window.onresize();
  }

  function update_cart_counter() {
    var $counter = $('.cart-menu .count');
    var counter_value = $counter.val();
    var new_count = 0;

    $("header .shopping-cart .shopping-row").each(function() {
      var $row = $(this);
      new_count += parseInt($row.find('.quantity').find('.qty').val());
    });

    $counter.text(new_count);
  }

  function init_quantity_controls() {
    $(".quantity-control__plus").click(function() {
      var $input = $(".summary .quantity input[type='number']");
      var current_value = parseInt($input.val());
      var max_quantity = parseInt($input.attr('max'));

      if(current_value !== max_quantity) {
        current_value++;
        $input.val(current_value);
      }
    });
    $(".quantity-control__minus").click(function() {
      var $input = $(".summary .quantity input[type='number']");
      var current_value = parseInt($input.val());
      var min_quantity = parseInt($input.attr('min'));

      if(current_value > min_quantity && current_value !== min_quantity) {
        current_value--;
        $input.val(current_value);
      }
    });
  }

function check_cart_empty() {
  var current_qty = $('header .shopping-cart').attr('data-cart-total-qty');

  if(current_qty == 0) {
    $('body').addClass('cart-empty');
  }
  else {
    $('body').removeClass('cart-empty');
  }
}
