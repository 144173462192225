function init_global_notice() {
    var $global_notice = $('.global-notice');

    if($global_notice.length > 0) {
        var notice_title_hash = string_to_slug($global_notice.find('.h4').text());

        if(getCookie('global_notice_' + notice_title_hash + '_hidden') != 'true') {
            $global_notice.fadeIn(500);
        }

        // Close the notice
        $global_notice.find('.global-notice__close').on('keypress click', function() {
            var $close_button = $(this);
            var $notice = $close_button.parent().parent();
            var hash_cookie_title = string_to_slug($notice.find('.h4').text());

            if(hash_cookie_title.length > 1) {
                setCookie('global_notice_' + hash_cookie_title + '_hidden', 'true', 5);
            }

            $close_button.parent().parent().fadeOut(500);
        });
    }
}

function string_to_slug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}