function init_banner() {
    $('.js-banner-slider').each(function() {
        var $this = $(this);
        $this.find('.items').PISliderJS({
            points: $this.find('.points'),
            loop: true,
            drag: true,
            gutter: 0,
            columns: 1,
            timing: 'ease-in-out',
            duration: 500,
            autoPlay: 3000
        });
    });
}