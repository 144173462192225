jQuery(document).ready(function($) {
    if(myLazyLoad) {
        myLazyLoad.update();
    }
    // var myLazyLoad = new LazyLoad();

    var iframelazy = new LazyLoad({
        elements_selector: "iframe",
        show_while_loading: true // a workaround to avoid LazyLoad use a placeholder src
    });

    init_header();
    init_banner();
    init_product_sort();
    init_cart(true);
    init_quantity_controls();
    init_global_notice();
    init_greet();
    init_product_gallery_fix();

    sal();

    window.setTimeout(function(){
        $(window).resize();
    },500);

    // Vacatures solliciteren
    $(".custom-file-input").on("change", function() {
        var fileName = $(this).val().split("\\").pop();
        $(this).parent().next(".custom-file-label").addClass("selected").html(fileName);
    });
});