function init_header() {
    $('.js-toggle-search').click(function(event) {
        event.preventDefault();
        var $search_overlay = $('.search-overlay');
        $search_overlay.fadeToggle(300);
        $('#header-search__input').focus();
    });

    $('.js-close-search').click(function(event) {
        event.preventDefault();
        var $search_overlay = $('.search-overlay');
        $search_overlay.fadeOut(300);
    });

    $('.minimal_days_notice').click(function(event) {
        event.preventDefault();
        $('.minimal_days_notice').remove();
    });



    $(document).keyup(function(e) {
        if (e.key === "Escape") { // escape key maps to keycode `27`
            var $search_overlay = $('.search-overlay');
            $search_overlay.fadeOut(300);
        }
   });

    $('#toggle-mobile-menu-button').click(function() {
        $('body').removeClass('cart-open');
    });

    if($("main > .banner").length > 0) {
        var topofDiv = $("main > .banner").offset().top; //gets offset of banner
        var height = ($("main > .banner").outerHeight() - $('nav.navbar').outerHeight()); //gets height of banner
        var $body = $('body');

        var scrollTimeout;
        var throttle = 150;
        var evaluate_header_color = function() {
            // If content is shifting
            if(height > 1000) {
                height = ($("main > .banner").outerHeight() - $('nav.navbar').outerHeight());
            }

            if($(window).scrollTop() > (topofDiv + height)){
                // Show pink color
                $body.addClass('header-is-pink');
            }
            else{
                $body.removeClass('header-is-pink');
            }
        };

        evaluate_header_color();

        $(window).on('scroll', function () {
            if (!scrollTimeout) {
                scrollTimeout = setTimeout(function () {
                    evaluate_header_color();
                    scrollTimeout = null;
                }, throttle);
            }
        });
    }
}